var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',[(_vm.isLoading)?_c('aom-skeleton-loader'):_c('section',{staticClass:"mt-2"},[_c('vue-good-table',{staticClass:"mt-3",attrs:{"mode":"remote","is-loading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.isRtl,"search-options":{
          enabled: false,
        },"pagination-options":{
          enabled: true,
          perPage: _vm.perPage,
        },"style-class":"vgt-table striped"},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'created_at')?_c('div',{staticClass:"justify-content-between flex-wrap",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.localeDateStringFromIsoDateTime( props.formattedRow[props.column.field]))+" ")]):(props.column.field === 'created_by.full_name' && props.row.created_by)?_c('div',{staticClass:"justify-content-between flex-wrap",attrs:{"cols":"12","md":"2"}},[_c('router-link',{staticClass:"table-primary-link",attrs:{"to":{
                name: 'champion-program-participant',
                params: { id: _vm.defaultProgramId, participantId: props.row.created_by.id },
              }},nativeOn:{"click":function($event){return _vm.markAsRead(props.row)}}},[_vm._v(" "+_vm._s(props.row.created_by.full_name)+" ")])],1):(props.column.field === 'read_at' && props.formattedRow[props.column.field])?_c('div',{staticClass:"justify-content-between flex-wrap",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.localeDateStringFromIsoDateTime( props.formattedRow[props.column.field]))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('table-pagination',{attrs:{"per-page":_vm.perPage,"total":_vm.total},on:{"perPageChanged":function (value) { return props.perPageChanged({ currentPerPage: value }); },"pageChanged":function (value) { return props.pageChanged({ currentPage: value }); }}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }