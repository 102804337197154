<template>
  <section>
    <b-card>
      <aom-skeleton-loader v-if="isLoading" />
      <section
        v-else
        class="mt-2"
      >
        <vue-good-table
          class="mt-3"
          mode="remote"
          :is-loading="isLoading"
          :columns="columns"
          :rows="rows"
          :rtl="isRtl"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
            perPage: perPage,
          }"
          
          style-class="vgt-table striped"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <div
              v-if="props.column.field === 'created_at'"
              cols="12"
              md="2"
              class="justify-content-between flex-wrap"
            >
              {{ localeDateStringFromIsoDateTime( props.formattedRow[props.column.field]) }}
            </div>
            <div
              v-else-if="props.column.field === 'created_by.full_name' && props.row.created_by"
              cols="12"
              md="2"
              class="justify-content-between flex-wrap"
            >
              <router-link
                :to="{
                  name: 'champion-program-participant',
                  params: { id: defaultProgramId, participantId: props.row.created_by.id },
                }"
                class="table-primary-link"
                @click.native="markAsRead(props.row)"
              >
                {{ props.row.created_by.full_name }}
              </router-link>
            </div>
            <div
              v-else-if="props.column.field === 'read_at' && props.formattedRow[props.column.field]"
              cols="12"
              md="2"
              class="justify-content-between flex-wrap"
            >
              {{ localeDateStringFromIsoDateTime( props.formattedRow[props.column.field]) }}
            </div>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
      
          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <table-pagination
              :per-page="perPage"
              :total="total"
              @perPageChanged="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
              @pageChanged="
                (value) => props.pageChanged({ currentPage: value })
              "
            />
          </template>
        </vue-good-table>
      </section>
    </b-card>
  </section>
</template>
      
<script>
import { 
  BCard
} from "bootstrap-vue";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import { commsService }  from "@/services";
import { mapGetters } from 'vuex';
import { makeErrorToast } from "@/libs/utils";
import { topicTypes, notificationType } from '@models';
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { localeDateStringFromIsoDateTime } from '@/libs/utils';
import useAomTableConfig from "@/@aom-core/useAomTableConfig";
export default {
  name: 'Notifications',
  components: {
    BCard,
    VueGoodTable,
    TablePagination,
    AomSkeletonLoader
  },
  data() {
    return {
      isLoading: false,
      columns: [    
        {
          label: "Message",
          field: "latest_message.0.subject",
          width: "12em",
          sortable: false
        },
        {
          label: "User",
          field: "created_by.full_name",
          width: "12em",
          sortable: false
        },
        {
          label: "Created At",
          field: "created_at",
          width: "12em",
          sortable: false
        },
        {
          label: "Read At",
          field: "read_at",
          width: "12em",
          sortable: false
        }
      ],
      rows: [],
      columnFilters: [{
        field: 'type_id', value: topicTypes.NOTIFICATION
      }],
      sort: [{ field: 'created_at', type: 'desc'}],
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),
    ...mapGetters('app', ['isParticipantPortal', 'isChampionPortal']),
    isRtl() {
      return store.state.appConfig.isRTL;
    }
  },
  created() {
    if(this.isParticipantPortal) {
      this.columns = this.columns.filter(c => c.field !== 'created_by.full_name');
    }
    this.loadItems();
  },
  methods: {
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
      
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id || this.defaultProgramId;
        const response = await commsService.getProgramsComms(programId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort
        }
        );
        const { items, total }  = response.data;
        this.total = total;
        this.rows = items;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }         
    },
    routeToEvent(notification) {
      if(notification?.latest_message[0]?.body) {
        const type = notification.latest_message[0].body;
        if(type === notificationType.NEW_REGO) {
          const participantId = notification.created_by_id;
          return {
            name: 'champion-program-participant',
            params: { id: this.defaultProgramId, participantId: participantId },
          };
        }
        return;
      }
      return;
    },
    async markAsRead(notification) {
      try {
        const programId = this.$route.params.id;
        const topicId = notification?.id;
        await commsService.postTopicMessageMarkRead(programId, {topics: [{id: topicId}]});
        this.$root.$emit("message-read", notification);
        this.loadItems();
      } catch(e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      }

    }
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();

    return {     
      localeDateStringFromIsoDateTime,
      total,
      perPage,
      page
    };
  }
};
</script>
      
<style lang="scss">
    @import '@/assets/scss/vue/libs/vue-good-table.scss';
</style>
      
      